import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';

import {
  changeThemeToDark,
  changeThemeToLight,
} from '../../redux/actions/themeActions';
import {
  changeCollapse,
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
} from '../../redux/actions/sidebarActions';

function Layout() {
  const dispatch = useDispatch();
  const isAgent = useSelector((state) => state.auth.isAgent);
  const sidebar = useSelector((state) => state.sidebar);

  useEffect(() => {
    if (isAgent) {
      dispatch(changeCollapse());
    }
  }, [isAgent]);

  const changeSidebarVisibilityFunc = () => {
    dispatch(changeSidebarVisibility());
  };

  const changeMobileSidebarVisibilityFunc = () => {
    dispatch(changeMobileSidebarVisibility());
  };

  const changeToDark = () => {
    dispatch(changeThemeToDark());
  };

  const changeToLight = () => {
    dispatch(changeThemeToLight());
  };

  const layoutClass = classNames({
    layout: true,
    'layout--collapse': sidebar.collapse,
  });

  return (
    <div className={layoutClass}>
      <Topbar
        changeMobileSidebarVisibility={changeMobileSidebarVisibilityFunc}
        changeSidebarVisibility={changeSidebarVisibilityFunc}
      />
      {!isAgent && (
        <Sidebar
          sidebar={sidebar}
          changeToDark={changeToDark}
          changeToLight={changeToLight}
          changeMobileSidebarVisibility={changeMobileSidebarVisibilityFunc}
        />
      )}
    </div>
  );
}

Layout.propTypes = {
  dispatch: PropTypes.func,
  sidebar: PropTypes.object,
};

export default Layout;
