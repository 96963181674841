import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import { useLanguage } from '../../../i18n/LanguageContext';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../redux/actions/authAction';

const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => {
  const { isRTL, language, toggleLanguage } = useLanguage();
  const dispatch = useDispatch();
  const isAgent = useSelector((state) => state.auth.isAgent);

  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className={'topbar__flip'}>
          {!isAgent ? (
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
          ) : (
            <div style={{ width: 20 }}></div>
          )}
          <Link
            className={
              isRTL ? 'topbar__logo logo__rtl' : 'topbar__logo logo__ltr'
            }
            to="/dashboard"
          />
        </div>
        <div
          className={
            isRTL ? 'topbar__flip topbar__button-container' : 'topbar__flip'
          }
        >
          <button
            type="button"
            className="topbar__button topbar__button--desktop language-button"
            onClick={() => toggleLanguage(language === 'en' ? 'ar' : 'en')}
          >
            {isRTL ? 'En' : 'Ar'}
          </button>
          <button
            type="button"
            className="topbar__button topbar__button--desktop language-button"
            onClick={() => dispatch(logout())}
          >
            {isRTL ? 'الخروج' : 'Logout'}
          </button>
        </div>
      </div>
    </div>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
